// utils.js
import moment from 'moment'

export default {
  isEmail (email) {
    const re = /[^\s@]+@[^\s@]+\.[^\s@]+/
    return re.test(email)
  },
  isUPN (upn) {
    const re = /[A-Z]{1}\d{12}/
    return re.test(upn)
  },
  isAlphaNumeric (str) {
    const patt = /^[a-z0-9]+$/i
    return patt.test(str)
  },
  isAlphaNumericPunctuated (str) {
    const patt = /^[a-z0-9-_.]+$/i
    return patt.test(str)
  },
  isAlpha (str) {
    const patt = /^[a-z]+$/i
    return patt.test(str)
  },
  isAlphaSpace (str) {
    const patt = /^[a-z\s]+$/i
    return patt.test(str)
  },
  isAlphaSpacePunctuated (str) { // for names
    const patt = /^[a-z\s'-]+$/i
    return patt.test(str)
  },
  isAlphaNumericSpace (str) {
    const patt = /^[a-z0-9\s]+$/i
    return patt.test(str)
  },
  isAlphaPunctuated (str) {
    const patt = /^[a-z'-]+$/i
    return patt.test(str)
  },
  isAlphaNumericSpacePunctuated (str) {
    const patt = /^[a-z0-9\s'&/-]+$/i
    return patt.test(str)
  },
  generatePassword () {
    const adj = ['red', 'big', 'old', 'fun', 'odd', 'blue', 'toy', 'new', 'bad', 'dry', 'top', 'shy', 'tin', 'tiny', 'tidy', 'sly']
    const noun = ['cat', 'dot', 'cap', 'ant', 'map', 'ram', 'gig', 'egg', 'jet', 'fox', 'sun', 'rug', 'bun', 'pup', 'pop', 'pin', 'car', 'mat', 'mop', 'man']

    const pw = adj[Math.floor(Math.random() * adj.length)] + noun[Math.floor(Math.random() * noun.length)] + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10)
    console.log(pw)
    return pw
  },
  isEuroVat (str) {
    const patt = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/i
    return patt.test(str)
  },
  formattedDateTime (d) {
    return moment(d).format('L LT')
  }
}
