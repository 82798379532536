<template>
  <div class="container container-table">
    <div class="columns is-centered is-multiline">
      <div class="container column is-8">
        <div style="max-width:18em; margin: 0 auto;">
          <a href="/" class="edshedlogo">
            <img src="/img/logo-edshed.png" alt="Logo" class="img-responsive center-block">
          </a>
        </div>
        <div class="center-block" style="width: fit-content; text-align: center">
          <img src="/img/logo.png" class="inline-block sublogo">
          <img v-if="$route.query.locale == 'en_US'" src="/img/math-shed-logo-dark.png" class="inline-block sublogo mathslogo">
          <img v-else src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo">
        </div>
      </div>
      <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->

      <div class="column is-8">
        <div class="box box-info" style="color:black">
          <div class="box-header content">
            <div class="text-center">
              <h1>{{ this.$route.name.toUpperCase() }}</h1>
            </div>
          </div>
          <div class="box-body">
            <div class="stepwizard">
              <div class="stepwizard-row">
                <div class="stepwizard-step">
                  <button type="button" class="btn button is-warning btn-circle" disabled="disabled">
                    1
                  </button>
                  <p>Create Account</p>
                </div>
                <div class="stepwizard-step">
                  <button type="button" class="btn button btn-circle" disabled="disabled">
                    2
                  </button>
                  <p>Add {{ locale == 'en_US' ? 'Organization' : 'Organisation' }}</p>
                </div>
                <div class="stepwizard-step">
                  <button type="button" class="btn button btn-circle" disabled="disabled">
                    3
                  </button>
                  <p>Subscribe</p>
                </div>
              </div>
            </div>

            <!-- login form -->
            <form class="loginForm" @submit.prevent="checkCreds">
              <b-field label="Name*">
                <b-input v-model="name" size="is-medium" placeholder="Your name" />
              </b-field>
              <b-field
                label="Username*"
                :type="{'is-success': usernameAvailable, 'is-danger': !usernameAvailable && username}"
                :message="{'Username not available.': !usernameAvailable && username}"
              >
                <b-input v-model="username" size="is-medium" placeholder="Choose a username (must be unique within EdShed)" @input="checkUsername" />
              </b-field>
              <b-field label="Email*">
                <b-input v-model="email" size="is-medium" placeholder="Your email address" type="email" />
              </b-field>
              <!-- <div class="form-group col-sm-12">
                    <label for="name" class="col-sm-2 control-label">Name*</label>
                    <div class="input-group">
                      <span class="input-group-addon"><i class="fa fa-user"></i></span>
                      <input class="form-control" name="name" placeholder="Name" type="text" v-model="name" autocomplete="off">
                    </div>
                  </div> -->
              <!-- <div class="form-group col-sm-12">
                    <label for="username" class="col-sm-2 control-label">Username*</label>
                    <div class="input-group">
                      <span class="input-group-addon"><i class="fa fa-users"></i></span>
                      <input class="form-control" name="username" placeholder="Username" type="text" v-model="username" autocomplete="off" v-on:input="checkUsername">

                      <span class="input-group-addon" style="background-color: hsl(141, 71%, 48%); color: white;" v-if="usernameAvailable"><i class="ion ion-ios-checkmark is-size-4"></i></span>
                      <span class="input-group-addon" style="background-color: hsl(348, 100%, 61%); color: white;" v-else><i class="ion ion-ios-close is-size-4"></i></span>
                    </div>
                  </div> -->
              <!-- <div class="form-group col-sm-12">
                    <label for="email" class="col-sm-2 control-label">Email*</label>
                    <div class="input-group">
                      <span class="input-group-addon"><i class="fa fa-envelope"></i></span>
                      <input class="form-control" name="email" placeholder="Email" type="text" v-model="email" autocomplete="off">
                    </div>
                  </div> -->
              <!-- <div class="form-group col-sm-12">
                    <label for="password" class="col-sm-2 control-label">School Code</label>
                    <div class="input-group">
                      <span class="input-group-addon"><i class="fas fa-key"></i></span>
                      <input class="form-control" name="password" placeholder="If you have a school code enter it here to join your school as a teacher" v-model="code" autocomplete="off">
                    </div>
                  </div> -->
              <div class="field">
                <label for="password" class="label">Password*</label>
                <div class="is-clearfix">
                  <password v-model="password" class="control" style="max-width: inherit;" @score="showScore" @feedback="showFeedback" />
                </div>
                <p v-for="fb in suggestions" v-if="!warning" class="notification">
                  💡{{ fb }}
                </p>
                <p v-if="warning" class="notification is-danger">
                  ⚠️ {{ warning }}
                </p>
              </div>

              <b-field label="Region*">
                <b-select v-model="locale" placeholder="Choose a region" size="is-medium" expanded>
                  <option value="en_AU">
                    🇦🇺 Australia
                  </option>
                  <option value="en_IE">
                    🇮🇪 Ireland
                  </option>
                  <option value="en_NZ">
                    🇳🇿 New Zealand
                  </option>
                  <option value="en_GB">
                    🇬🇧 United Kingdom
                  </option>
                  <option value="en_US">
                    🇺🇸 United States
                  </option>
                </b-select>
              </b-field>
              <p class="is-clearfix" style="margin-bottom:20px;">
                <small><i>The region chosen will affect the spelling list language and voice used in our game. This can be changed later.</i></small>
              </p>
              <!-- <div class="form-group col-sm-12">
                    <label for="password" class="col-sm-2 control-label">Region*</label>
                    <div class="input-group col-sm-10">
                      <select v-model="locale" class="form-control input-group">
                        <option value="en_AU">🇦🇺 Australia</option>
                        <option value="en_IE">🇮🇪 Ireland</option>
                        <option value="en_NZ">🇳🇿 New Zealand</option>
                        <option value="en_GB">🇬🇧 United Kingdom</option>
                        <option value="en_US">🇺🇸 United States</option>
                      </select>
                    </div>
                    <p><small><i>The region chosen will affect the spelling list language and voice used in our game. This can be changed later.</i></small></p>
                  </div>-->
              <div class="notification is-warning content">
                <h3>If you have a <b>School Joining Code</b> you will be asked to use this in the next step.</h3>
              </div>
              <b-field label="Updates*" horizontal>
                <b-switch
                  v-model="marketing"
                  type="is-success"
                >
                  <small v-if="marketing">Yes</small><small v-else>No</small>
                </b-switch>
              </b-field>
              <p style="margin-bottom:20px;">
                <small><i>Allow us to send emails to you about new features and offers. N.B. We do not share data with third parties.</i></small>
              </p>
              <!-- <div class="form-group col-sm-12">
                    <label for="password" class="col-sm-2 control-label">Updates*</label>
                    <div class="input-group col-sm-10 has-text-left">
                      <b-switch v-model="marketing"
                        type="is-success"><small v-if="marketing">Yes</small><small v-else>No</small>
                      </b-switch>
                      <p><small><i>Allow us to send emails to you about new features and offers. N.B. We do not share data with third parties.</i></small></p>
                    </div>
                  </div> -->
              <div class="box box-white">
                <p>By signing up you are agreeing to our <a href="https://www.edshed.com/terms" target="_blank">Terms of Use</a> and <a href="https://www.edshed.com/privacy" target="_blank">Privacy Policy</a>.</p>
              </div>

              <div v-if="response" class="alert alert-error col-sm-12">
                <p>{{ response }}</p>
              </div>
              <div class="has-text-centered" style="margin: 30px;">
                <button type="submit" :class="{'button': true, 'is-link': true, 'loading': loading}" :disabled="(!usernameAvailable || warning != '' || password.trim() == '' || score < 2 || password.length < 7)">
                  Register
                </button>
                <p v-if="!usernameAvailable" class="moreMargin">
                  <small class="tag is-danger"><span class="icon"><i class="ion ion-ios-warning" /></span> <span>A unique username is required. If you already have an account <router-link to="/login" style="text-decoration: underline;">SIGN IN HERE</router-link>.</span></small>
                </p>
                <p v-if="score < 2 || warning != ''" class="moreMargin">
                  <small class="tag is-danger"><span class="icon"><i class="ion ion-ios-warning" /></span> <span>A more secure password is required.</span></small>
                </p>
                <p v-if="password.length < 7" class="moreMargin">
                  <small class="tag is-danger"><span class="icon"><i class="ion ion-ios-warning" /></span> <span>Passwords must be 7 characters or more.</span></small>
                </p>
              </div>
            </form>

            <div class="has-text-centered">
              <p>&nbsp;</p>
              <div style="width: 100%; height: 20px; border-bottom: 1px solid #ccc; text-align: center">
                <span style="font-size: 22px; background-color: white; padding: 0 10px;">
                  Or
                </span>
              </div>
              <p>&nbsp;</p>
              <a class="has-text-centered" href="https://clever.com/oauth/authorize?response_type=code&redirect_uri=https%3A%2F%2Fwww.edshed.com%2Floginclever&client_id=15b57c55d6bf89fb3cfb"><img src="/img/signupclever.png" style="height:36px"></a>
              <p>&nbsp;</p>
            </div>
            <!-- errors -->
            <div class="text-center col-md-12">
              <router-link to="/login">
                Already have an account? Sign in.
              </router-link> | <a href="https://play.edshed.com">Web Game</a> | <a href="https://www.edshed.com/store">Store</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <footer class="row">
      <div class="container">
        <div class="text-center col-md-4 col-sm-offset-4">
          <p style="font-size:14px; color: #4a4a4a;">
            Copyright &copy; {{ new Date().getFullYear() }} Education Shed Ltd.
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Password from 'vue-password-strength-meter'
import utils from '@/utils'
import { request } from '@/edshed-common/api'

export default {
  name: 'Register',
  components: {
    Password
  },
  data (router) {
    return {
      section: 'Register',
      loading: '',
      name: '',
      username: '',
      email: '',
      password: '',
      marketing: true,
      code: '',
      response: '',
      locale: 'en_GB',
      suggestions: [],
      warning: '',
      score: 0,
      usernameAvailable: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.$router.push({ path: '/noaccess' })
      // if (this.$store.state.user != null) {
      //   this.$router.push({ path: 'add-school' })
      // }
      // if (this.$route.query.locale) {
      //   this.locale = this.$route.query.locale
      // } else {
      //   const loc = navigator.language.replace('-', '_')
      //   if (loc === 'en_US') {
      //     this.locale = loc
      //   }
      // }
      // if (this.$route.query.code) {
      //   this.getSchoolForCode(this.$route.query.code)
      // }
    })
  },
  methods: {
    getSchoolForCode (code) {
      // get school by code from api.
    },
    checkUsername (e) {
      const username = this.username
      if (username.trim() === '' || username.trim().length < 4 || !utils.isAlphaNumericPunctuated(username.trim())) {
        this.usernameAvailable = false
        return
      }
      request('GET', 'users/exists/' + username, null)
        .then((response) => {
          const data = response.data
          this.usernameAvailable = !data.exists
        })
    },
    showFeedback ({ suggestions, warning }) {
      console.log('🙏', suggestions)
      console.log('⚠', warning)
      this.suggestions = suggestions
      this.warning = warning
    },
    showScore (score) {
      console.log('💯', score)
      this.score = score
    },
    checkCreds () {
      const { name, username, email, password, code, locale } = this

      if (name.trim() === '') {
        this.response = 'Name Required'
        return
      } else if (!utils.isAlphaSpacePunctuated(name.trim())) {
        this.response = 'Name can only contain letters, "\'" and "-".'
        return
      } else if (username.trim() === '') {
        this.response = 'Username Required'
        return
      } else if (!utils.isAlphaNumeric(username.trim())) {
        this.response = 'Username can only contain letters and numbers.'
        return
      } else if (password.trim() === '') {
        this.response = 'Password required'
        return
      } else if (name.trim().length < 2) {
        this.response = 'Name must be 2 characters or more'
        return
      } else if (email.trim() !== '' && !utils.isEmail(email.trim())) {
        this.response = 'Email address must be valid'
        return
      } else if (password.trim().length < 7) {
        this.response = 'Password must be 7 characters or more.'
        return
      } else if (email.trim() === '') {
        this.response = 'Email Required'
        return
      } else if (this.score < 2 || this.warning !== '') {
        this.response = 'Password Insecure'
        return
      }
      // validate characters allowed

      this.toggleLoading()
      this.resetResponse()
      this.$store.commit('TOGGLE_LOADING')

      /* Making API call to authenticate a user */
      const p = { name, username, email, password, code, locale }
      p.marketing = this.marketing ? 1 : 0
      request('POST', 'users', p)
        .then((response) => {
          this.toggleLoading()
          window.console.log('Response', response)
          const data = response.data

          /* TODO:  Checking if error object was returned from the server */
          if (data.error) {
            const errorName = data.error.name
            if (errorName) {
              this.response = errorName === 'InvalidCredentialsError'
                ? 'Email / Password incorrect. Please try again.'
                : errorName
            } else {
              this.response = data.error
            }

            return
          }

          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            const token = data.user.token
            this.$store.commit('SET_USER', data.user)
            this.$store.commit('SET_TOKEN', token)

            console.log('token' + token)
            console.log('user' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
              window.localStorage.setItem('token', token)
            }

            this.$store.commit('TOGGLE_LOADING')
            this.$router.push({ path: '/add-school', query: this.$route.query })
          }
        })
        .catch((error) => {
          this.$store.commit('TOGGLE_LOADING')
          console.log(error)
          if (error.code === 400) {
            this.response = 'Duplicate email'
          } else {
            this.response = 'Details incorrect'
          }
          this.toggleLoading()
        })
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    resetResponse () {
      this.response = ''
    }
  }
}
</script>

<style scoped>
  body {
    background-image: url('/public/img/skyBackground.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-color: #b4e3f7;
    background-color: #f8f8f8;
  }
</style>

<style>

body, .container-table {
  height: 100%;
}

.container-table {
    display: table;
    color: white;
}
.vertical-center-row {
    display: table-cell;
    vertical-align: middle;
}
.vertical-20p {
  padding-top: 20%;
}
.vertical-10p {
  padding-top: 10%;
}
.logo {
  width: 15em;
  padding: 1em 1em 0;
}

.logo.mathslogo{
  padding-top: 0.5em;
}
.loginForm .input-group {
  padding-bottom: 1em;
  height: 4em;
}
.input-group input {
  height: 4em;
}

.stepwizard-step {
  width: 33%;
}

.stepwizard-step p {
  margin-top: 10px;
}

.stepwizard-row {
  display: table-row;
}

.stepwizard {
  display: table;
  width: 100%;
  position: relative;
}

.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-index: 0;

}

.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}

.Password__field {
  border: 1px solid #dbdbdb !important;
  border-radius: 3px;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  padding: 7px;
}
.Password__badge {
  text-align: center;
  box-sizing: content-box;
}

p.moreMargin {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
